.banner-box {
    width: 100%;
    height: calc(100vh - 64px);
    position: relative;
    overflow-x: hidden;
    top: 0;
    left: 0;

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .swiper-container-horizontal>.swiper-pagination-bullets {
        bottom: 20px;
    }

    .swiper-container {
        width: 100%;
        height: 100%;

        .swiper-button-white {
            background-repeat: no-repeat;
            background-size: 32px, 62px;
            background-position: center;
            z-index: 1000;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }

    .swiper-container {
        --swiper-navigation-color: #9d9d9d;
    }

    // .swiper-button-next, .swiper-container-rtl .swiper-button-prev{
    //     right:32px;
    // }
    // .swiper-button-prev, .swiper-container-rtl .swiper-button-next{
    //     left:32px;
    // }
    .swiper-button-prev {
        left: 32px;
    }

    .swiper-button-next {
        right: 32px;

    }

    .swiper-button-next,
    .swiper-button-prev {
        width: 32px;
        height: 62px;
        padding: 20px 30px;
    }


    .img {
        width: 100%;
        height: 100%;

        .drone-content {
            position: absolute;

            h1 {
                font-size: 48px;
                letter-spacing: 4px;
                margin-bottom: 2.6vw;
                color: #f2f2f2;

                span {
                    font-size: 36px;
                }
            }

            p {
                font-size: 30px;
                letter-spacing: 3px;
                color: #f2f2f2;
                margin-bottom: 3.125vw;
            }

            a {
                display: block;
                color: #000;
                width: 210px;
                height: 60px;
                font-size: 30px;
                letter-spacing: 3px;
                border: solid 2px #000;
                border-radius: 30px;
                text-align: center;
                line-height: 60px;
            }
        }
    }

    .drone-B100P {
        .drone-content {
            // width: 100%;
            top: 15%;
            right: 5%;
            text-align: center;
            h1 {
                width: 100%;
                text-align: center;
                margin-bottom: 100px;
                font-size: 80px;
                color: #333;
            }

            p {
                font-size: 48px;
                margin-bottom: 2vw;
                margin-right: 30px;
                color: #333;
                span:nth-of-type(2) {
                    font-size: 28px;
                }
            }
        }
    }


    .C1-drone {
        .drone-content {
            width: 100%;
            top: 25%;
            left: 0;
            text-align: center;
            h1 {
                width: 100%;
                text-align: center;
                margin-bottom: 100px;
                font-size: 56px;
                color: #fff;
            }

            p {
                font-size: 48px;
                margin-bottom: 2vw;
                margin-right: 30px;
                color: #333;
                span:nth-of-type(2) {
                    font-size: 28px;
                }
            }
        }
    }


    .T1-drone {
        .drone-content {
            top: 25%;
            left: 10%;
            text-align: center;
            h1 {
                margin-bottom: 100px;
                font-size: 56px;
                color: #333;
            }

            p {
                font-size: 48px;
                margin-bottom: 2vw;
                margin-right: 30px;
                color: #333;
                span:nth-of-type(2) {
                    font-size: 28px;
                }
            }
        }
    }

    .multipurpose-drone {
        .drone-content {
            top: 13%;
            left: 10%;
            text-align: center;
            h1 {
                margin-bottom: 200px;
                font-size: 56px;
                color: #333;
            }

            p {
                font-size: 48px;
                margin-bottom: 2vw;
                margin-right: 30px;
                color: #333;
                span:nth-of-type(2) {
                    font-size: 28px;
                }
            }


        }
    }

    .drone-100 {
        .drone-content {
            top: 10%;
            left: 8%;
            text-align: center;

            h1 {
                margin-bottom: 50px;
                font-size: 56px;
            }

            p {
                font-size: 48px;
                margin-bottom: 2vw;
                margin-right: 30px;

                span:nth-of-type(2) {
                    font-size: 28px;
                }
            }

            div {
                display: flex;
            }

        }
    }

    .drone-A22P {
        .drone-content {
            top: 30%;
            left: 25%;
            transform: translate(-50%, 0);
            text-align: center;

            h1 {
                margin-bottom: 2vw;
                font-size: 80px;
                color: #333;
            }

            p {
                position: relative;
                margin-bottom: 2vw;
                color: #333;
                font-size: 48px;
                &::before {
                    left: 10px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    width: 80px;
                    height: 2px;
                    position: absolute;
                    display: block;
                    content: "";
                    background-color: #333;
                }
                &::after {
                    right: 10px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    width: 80px;
                    height: 2px;
                    position: absolute;
                    display: block;
                    content: "";
                    background-color: #333;
                }
            }
        }
    }

    .drone-B70 {
        .drone-content {
            top: 30%;
            left: 8%;
            // transform: translate(-50%, 0); 
            text-align: center;

            h1 {
                margin-bottom: 1.5vw;
                font-size: 80px;
                color: #333;
            }

            p {
                position: relative;
                color: #333;
                font-size: 48px;
                margin-bottom: 2vw;

                span:nth-of-type(2) {
                    font-size: 28px;
                }

                &::before {
                    left: -10px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    width: 40px;
                    height: 2px;
                    position: absolute;
                    display: block;
                    content: "";
                    background-color: #333;
                }
                &::after {
                    right: -10px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    width: 40px;
                    height: 2px;
                    position: absolute;
                    display: block;
                    content: "";
                    background-color: #333;
                }
            }

            a {
                width: 170px;
                height: 44px;
                line-height: 44px;
                font-size: 22px;
                margin: 0 auto;
            }
        }
    }

    .drone-A22 {
        .drone-content {
            top: 30%;
            left: 10%;
            text-align: center;

            h1 {
                margin-bottom: 1.5vw;
                font-size: 80px;
                color: #333;
            }

            p {
                font-size: 48px;
                color: #333;
                margin-bottom: 2vw;
                position: relative;

                &::before {
                    left: -10px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    width: 40px;
                    height: 2px;
                    position: absolute;
                    display: block;
                    content: "";
                    background-color: #333;
                }
                &::after {
                    right: -10px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    width: 40px;
                    height: 2px;
                    position: absolute;
                    display: block;
                    content: "";
                    background-color: #333;
                }
            }

            a {
                width: 170px;
                height: 44px;
                line-height: 44px;
                font-size: 22px;
                margin: 0 auto;
            }
        }
    }

    .drone-A16 {
        .drone-content {
            top: 33.47%;
            left: 15.57%;
        }

    }

    .drone-A6 {
        .drone-content {
            top: 34.53%;
            right: 14.79%;
            text-align: right;

            a {
                float: right;
            }

        }

    }

    .drone-Q10 {
        .drone-content {
            top: 34.53%;
            right: 14.79%;
            text-align: right;

            a {
                float: right;
            }
        }

    }
}