.main {
  width: 100%;
  // padding: 0 146px;,
  box-sizing: border-box;
  margin-top: 116px;

  img {
    display: block;
    width: 100%;
  }


  .card-module {
    * {
      box-sizing: border-box;
    }


    .card-box .card {
      border-radius: 20px;
      display: flex;
      box-sizing: border-box;
      // margin: 30px 0;
      font-size: 35px;
      position: relative;
      // border: 1px solid #e6e6e6;
      overflow: hidden;

      .card-text {
        position: absolute;
        padding: 20px;
        left: 0;
        top: 0;
        height: 100%;
        text-align: center;
        width: 100%;

        h3 {
          margin: 50px 0;
          color: #000;
          font-size: 56px;
        }

        p {
          font-size: 20px;
          margin: 50px 0;
          color: inherit;

          span {
            color: #000;
            padding-right: 30px;

            & {
              position: relative;
            }

            &::after {
              position: absolute;
              content: '';
              top: 55%;
              right: 0;
              transform: translate(-50%, -50%) rotate(45deg);
              width: 7px;
              height: 7px;
              border-top: 2px solid #333;
              border-right: 2px solid #333;
              content: '';
              display: block;
            }
          }

        }


        p:nth-of-type(1) {
          position: relative;
          font-size: 23px;
        }

        p:nth-of-type(2) {
          // font-size: 24px;
        }

      }

      a{
        display: block;
        width: 100%;
      }

      img {
        // position: absolute;
        // left: 0;
        // top: 0;
        width: 100%;
        // z-index: -1;
        transition: all .3s ease-in-out;
      }



      &:hover {
        img{
          transform: scale(1.1);
        }
      }
    }

    .card-box:nth-of-type(1) {
      display: grid;
      grid-template-columns: 42.5% 35%;
      gap: 30px 30px;
      justify-content: center;


      .card:nth-of-type(1) {
        // width: 813px;
        // height: 1000px;
        grid-row-start: span 2;
     

        .card-text p:nth-of-type(1){
          color: #424242;
        }

        .card-text p:nth-of-type(1)::before {
          right: calc(50% - 1px);
          border-radius: 1.5px;
        }

        .card-text p:nth-of-type(1)::after {
          left: calc(50% - 1px);
          border-radius: 1.5px;
        }

        .card-text p:nth-of-type(1)::before,
        .card-text p:nth-of-type(1)::after {
          content: '';
          display: block;
          position: absolute;
          width: 0;
          height: 3px;
          bottom: -20px;
          transition: all 0.5s ease-in-out;
          background-color: #0090e7;
        }

        &:hover .card-text p:nth-of-type(1)::before,
        &:hover .card-text p:nth-of-type(1)::after {
          width: 40px;
        }
      }

      .card:nth-of-type(2),
      .card:nth-of-type(3) {
        .card-text p:nth-of-type(1)::before {
          position: absolute;
          display: block;
          content: '';
          width: 40px;
          height: 3px;
          background-color: #0090e7;
          bottom: -35px;
          border-radius: 1.5px;
          left: 0;
          transition: all 0.5s ease-in-out;
          opacity: 0;
        }

        &:hover {
          .card-text p:nth-of-type(1)::before {
            opacity: 1;
            bottom: -20px;
          }
        }
      }

      .card:nth-of-type(2) {
        // width: 671px;
        // height: 477px;
        align-self: start;

        .card-text {
          text-align: left;
          width: 250px;
          right: 0;
          margin: 0 0 0 auto;

          p:nth-of-type(1){
            color: #424242;
          }

          p:nth-of-type(2) {
            margin-top: 9vw;
          }
        }

      }

      .card:nth-of-type(3) {
        // width: 671px;
        // height: 477px;
        align-self: end;


        .card-text {
          text-align: left;
          width: 250px;
          right: 0;
          margin: 0 0 0 auto;

          p:nth-of-type(1){
            color: #424242;
          }


          p:nth-of-type(2) {
            margin-top: 9vw
          }
        }
      }

    }

    .card-box:nth-of-type(2) {
      margin: 30px;
      display: grid;
      grid-template-columns: repeat(3, 26%);
      // grid-template-rows: repeat(3, auto);
      justify-content: center;
      gap: 30px 30px;

      .card {
        // height: 481px;

        .card-text{
          text-align: left;
          h3{
            margin: 20px 0;
          }

          p:nth-of-type(1){
            color: #424242;
          }



          p:nth-of-type(1)::before {
            position: absolute;
            display: block;
            content: '';
            width: 40px;
            height: 3px;
            opacity: 0;
            background-color: #0090e7;
            border-radius: 1.5px;
            bottom: -35px;
            left: 0;
            transition: all 0.5s ease-in-out;
          }
  
          &:hover {
            p:nth-of-type(1)::before {
              opacity: 1;
              bottom: -20px;
            }
          }


          p:nth-of-type(2){
            margin: 0;
            margin-top: 9vw;
          }
        }

        
      }

      .card:nth-of-type(1) {
        justify-content: start;
        align-items: start;
      }

    }

    .card-box:nth-of-type(3) {
      width: calc(77% + 30px);
      height: auto;
      margin: 0 auto;

      .card{
        width: 100%;
        // height: 738px;
        background-color: #f5f5f5;

        .card-text{
          text-align: left;
          width: auto;
          h3{
            margin: 20px 0;
          }

          p:nth-of-type(1){
            color: #424242;
            text-align: center;
          }
          p:nth-of-type(2){
            text-align: center;
          }
        }

        a{
         text-align: right;
        }

        img{
          // position: absolute;
          // right: 0;
          // bottom: 0;
          display: inline-block;
          width: 70%;
          height: auto;
        }

      }
    }



  }

  .scenes {
    margin-top: 60px;
    color: #fff;

    position: relative;

    .scenes-bg{
      width: 100%;
    }

    .scenes-content{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      h2 {
        text-align: center;
        // margin: 100px 0;
        font-size: 35px;
        position: relative;
  
        &::after {
          position: absolute;
          content: '';
          display: block;
          width: 80px;
          height: 5px;
  
          left: 0;
          right: 0;
          margin: 0 auto;
          bottom: -30px;
          background-color: #0090e7;
          border-radius: 2.5px;
        }
      }
  
      .scenes-child {
        display: flex;
        justify-content: space-evenly;
        transform: translateX(-25px);
  
        li {
          // width: 250px;
          text-align: center;
          display: flex;
          align-items: center;
  
          img {
            display: block;
            width: 50px;
            height: 50px;
            margin: 20px;
          }
  
          font-size: 26px;
        }
      }
    }
  }
}